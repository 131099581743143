import * as React from "react";
import { Container, Box, Grid, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// utils
import Link from "../utils/link";
import Page from "../utils/page";
import theme from "../utils/theme";
import Hero from "../utils/hero";

// markup
const NotFoundPage = () => {
    return (
        <Page title="404" description="Pagina non trovata" type="WebPage">
            <Hero
                title="Pagina non trovata"
                description=""
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../images/backgrounds/fragranza-menta.jpg"
                        alt="Fragranza menta"
                        layout="fullWidth"
                    />
                }
            />
            <Container style={{ minHeight: "calc(100vh - 400px)" }}>
                <Box
                    sx={{
                        py: [5, 7],
                    }}
                >
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} sm={6} md={7}>
                            <Stack spacing={1}>
                                <Box textAlign="center">
                                    <Link type="internal" href="/" title="Torna alla home" underline="always">
                                        Torna alla home
                                    </Link>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
};

export default NotFoundPage;
